import { css } from '@emotion/react';
import styled from '@emotion/styled';
import Plain from './Plain';

export default styled(Plain)<{ light?: boolean; big?: boolean }>`
    padding: ${({ big }) => (big ? '44px 24px' : '24px')};
    gap: 12px;
    background-color: ${({ theme, light }) => (light ? theme.colors.gray1 : theme.colors.blue4)};
    color: ${({ theme, light }) => (light ? theme.colors.gray6 : theme.colors.gray1)};
    transition: background-color 0.3s ease-in-out;
    text-transform: capitalize;
    font-weight: 600;

    > svg {
        flex-shrink: 0;
    }

    :hover {
        background-color: ${({ theme, light }) =>
            light ? theme.colors.blue1 : theme.colors.blue3};
    }

    :focus-visible {
        background-color: ${({ theme, light }) =>
            light ? theme.colors.blue1 : theme.colors.blue3};
    }

    ${({ light, theme }) =>
        light &&
        css`
            box-shadow:
                inset 0 -1px 0 ${theme.colors.gray3},
                inset 0 1px 0 ${theme.colors.gray3};
        `};
`;
