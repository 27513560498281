import { css } from '@emotion/react';

export const h1 = css`
    font-family: 'Fira Sans', serif;
    font-size: 3rem;
    font-weight: 700;
    letter-spacing: 0.48px;
    line-height: 120%;

    @media (min-width: 1280px) {
        font-size: 4rem;
    }
`;

export const h2 = css`
    font-size: 2.5rem;
    font-weight: 500;
    font-family: 'Fira Sans', serif;
    line-height: 120%;

    @media (min-width: 1280px) {
        font-size: 3rem;
    }
`;

export const h3 = css`
    font-size: 2rem;
    font-weight: 600;
    line-height: 120%;

    @media (min-width: 1280px) {
        font-size: 2.5rem;
    }
`;

export const h4 = css`
    font-size: 1.5rem;
    font-weight: 600;
    line-height: 120%;

    @media (min-width: 1280px) {
        font-size: 2rem;
    }
`;

export const h5 = css`
    font-size: 1.25rem;
    line-height: 136.2%;
    font-weight: 600;
    letter-spacing: 1px;

    @media (min-width: 1280px) {
        font-size: 1.5rem;
    }
`;

export const smTxt = css`
    font-size: 0.875rem;
`;

export const lgTxt = css`
    font-size: 1.125rem;

    @media (min-width: 1280px) {
        font-size: 1.25rem;
    }
`;

export const redTxt = css`
    font-size: 0.875rem;
    font-weight: 600;
    color: #b31942;
    margin: 0 0 8px;
`;
