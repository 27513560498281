import { useState } from 'react';
import { PatternFormat } from 'react-number-format';
import { Error, Form, Input, Label, Thanks } from '../FormComponents';
import { PrimaryBtn } from '../buttons';

export const ContactForm = () => {
    const [submit, setSubmit] = useState<'init' | 'submitting' | 'submitted' | 'error'>('init');

    const handleSubmit = async (e: React.ChangeEvent<HTMLFormElement>) => {
        e.preventDefault();
        setSubmit('submitting');

        const data = Object.fromEntries(new FormData(e.currentTarget));

        await fetch(`https://api.${window.location.host}/contact`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        })
            .then(() => {
                setSubmit('submitted');
                e.target.reset();
            })
            .catch(() => {
                setSubmit('error');
            });
    };

    return (
        <Form
            onSubmit={handleSubmit}
            submit={submit === 'submitted' || submit === 'error'}
            id="contact-us"
        >
            <Label htmlFor="name">
                <span>Name</span>
                <Input type="text" id="name" name="name" placeholder="Full Name" required />
            </Label>

            <Label htmlFor="email">
                <span>Email address</span>
                <Input
                    type="email"
                    id="email"
                    name="email"
                    required
                    placeholder="example@gmail.com"
                />
            </Label>
            <Label htmlFor="phone">
                <span>Phone number</span>
                <PatternFormat
                    type="tel"
                    format="+1 (###) ###-####"
                    customInput={Input}
                    placeholder="123 456-7890"
                    id="phone"
                    name="phone"
                    required
                    pattern="^(\+1)[ ](\d{3}|\(\d{3}\))[\s\-]?\d{3}[\s\-]?\d{4}$"
                />
            </Label>

            <Label htmlFor="message">
                <span>Message</span>
                <Input
                    as="textarea"
                    id="message"
                    name="message"
                    rows={4}
                    placeholder="Write to us..."
                />
            </Label>

            <PrimaryBtn disabled={submit === 'submitting'} type="submit">
                {submit === 'submitting' ? 'Sending...' : 'Submit'}
            </PrimaryBtn>
            <Thanks submit={submit === 'submitted'} setSubmit={setSubmit} />
            <Error error={submit === 'error'} />
        </Form>
    );
};
