import { ReactComponent as RightArrow } from '@a/icons/right-arrow-circle-sm.svg';
import { ReactComponent as BigArrow } from '@a/icons/right-arrow-circle.svg';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { ElementType } from 'react';
import Plain from './Plain';

const Btn = styled(Plain)<{ light?: boolean }>`
    display: inline-grid;
    grid-template-columns: fit-content max-content;
    grid-template-rows: 1fr;
    color: ${({ theme, light }) => (light ? theme.colors.gray1 : theme.colors.blue4)};
    transition: color 0.3s ease-in-out;
    text-transform: capitalize;
    font-weight: 600;
    gap: 16px;
    line-height: 152.5%;
    overflow: hidden;

    > svg {
        transition: opacity 0.3s ease-in-out;
        grid-column: 2;
        grid-row: 1;

        :last-of-type {
            opacity: 0;
        }

        circle {
            transition: stroke 0.3s ease-in-out;
        }

        path {
            transition: fill 0.3s ease-in-out;
        }
    }

    :hover {
        color: ${({ theme }) => theme.colors.blue3};

        > svg {
            :first-of-type {
                opacity: 0;
            }

            :last-of-type {
                opacity: 1;
            }
        }
    }

    :focus-visible {
        color: ${({ theme }) => theme.colors.blue3};

        > svg {
            :first-of-type {
                opacity: 0;
                transform: rotate(24deg);
            }

            :last-of-type {
                opacity: 1;
                transform: rotate(0);
            }
        }
    }

    ${({ light, theme }) =>
        light &&
        css`
            > svg {
                circle {
                    stroke: ${theme.colors.gray1};
                }

                path {
                    fill: ${theme.colors.gray1};
                }
            }

            :hover {
                color: ${theme.colors.gray5};

                > svg {
                    circle {
                        stroke: ${theme.colors.gray6};
                    }

                    path {
                        fill: ${theme.colors.gray6};
                    }
                }
            }

            :focus-visible {
                color: ${theme.colors.gray5};

                > svg {
                    circle {
                        stroke: ${theme.colors.gray6};
                    }

                    path {
                        fill: ${theme.colors.gray6};
                    }
                }
            }
        `};
`;

type ArrowProps = React.HTMLAttributes<HTMLButtonElement> & {
    children?: React.ReactNode;
    as?: ElementType;
    to?: string;
    light?: boolean;
};

export default function ArrowBtn({ children, ...props }: ArrowProps) {
    return (
        <Btn {...props}>
            {children} <RightArrow /> <BigArrow />
        </Btn>
    );
}
